<template>
  <b-container
    style="height: 100vh"
    class="d-flex align-items-center justify-content-center"
  >
    <div
      class="my-sm-5 p-sm-5 text-center border justify-content-between align-items-center"
    >
      <h3>Your payment has been canceled!</h3>
      <vac :end-time="new Date().getTime() + 3 * 1000" @finish="finish()">
        <template v-slot:process="{ timeObj }">
          <h3>
            {{ `Redirect to the reservation progress in (${timeObj.s})` }}
          </h3>
        </template>
        <template v-slot:finish>
          <h3>You are now redirect!</h3>
        </template>
      </vac>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
export default {
  computed: {
    listing() {
      if (this.$store.getters.listing) {
        return this.$store.getters.listing[0];
      } else {
        return "";
      }
    }
  },
  methods: {
    finish() {
      this.$router.push({
        name: "ListingVehicle"
        // params: { id: this.listing.l_ListingId }
      });
    },
    async sendID() {
      try {
        await axios.post("SP/Post/sp_ListingPaymentFailed_Write", {
          Id: this.$route.query.id
        });
      } catch (error) {
        console.log(error.response.data);
      }
    }
  },
  beforeCreate() {
    document.getElementById("header").style.display = "none";
    document.getElementById("footer").style.display = "none";
    document.getElementById("socket").style.display = "none";
  },
  beforeMount() {
    this.sendID();
  },
  beforeDestroy() {
    document.getElementById("header").style.display = "block";
    document.getElementById("footer").style.display = "block";
    document.getElementById("socket").style.display = "block";
    this.$store.dispatch("listingStockID", null);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name) {
        vm.$router.push({ name: "Home" });
      }
    });
  }
};
</script>
